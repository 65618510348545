<!-- <template>
  <v-row class="row-center">
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        top
        right
        rounded="pill"
        color="teal"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
    <v-col
      cols="10"
      offset="1"
      xl="4"
      offset-xl="4"
      md="6"
      offset-md="3"
      sm="8"
      offset-sm="2"
      class="login_content"
    >
      <div class="form">
        <div class="form-style">
          <img src="@/assets/logo1.png" alt="Logo" />
          <div class="bloc-form">
            <h1 class="form-title">Vous avez oublié votre mot de passe ?</h1>
            <p class="init-password">
              Pour réinitialiser votre mot de passe, veuillez entrer votre email
            </p>
            <forgetPassword
              @emailOK="sendEmail"
              :fields="fields"
              :btn="btn"
            ></forgetPassword>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template> -->

<template>
  <v-container id="pageLogin" fluid>
    <v-row align="center">
      <!-- <v-row align="center" justify="center"> -->
      <v-col
        cols="10"
        offset="1"
        xl="4"
        offset-xl="4"
        md="6"
        offset-md="3"
        sm="8"
        offset-sm="2"
        class="login_content"
      >
        <v-row align="center">
          <v-col
            cols="6"
            xl="10"
            offset="3"
            lg="8"
            offset-lg="2"
            md="10"
            offset-md="1"
            sm="10"
            offset-sm="1"
            class="form-style"
          >
            <v-card class="login">
              <v-row
                class="form"
                align="center"
                align-content="center"
                justify="center"
              >
                <v-col cols="12">
                  <img src="@/assets/logo1.png" alt="Logo" />
                  <v-row class="bloc-form">
                    <v-col>
                      <h1 class="form-title mt-12 mb-3">
                        Vous avez oublié votre mot de passe ?
                      </h1>
                      <p class="init-password">
                        Pour réinitialiser votre mot de passe, veuillez entrer
                        votre email
                      </p>
                      <v-col cols="8" offset="2">
                        <sharedForm
                          id="formShared"
                          :fields="fields"
                          :btn="btn"
                          @formOK="sendEmail"
                        ></sharedForm>
                        <v-col cols="8" offset="2">
                          <v-btn
                          plain
                            width="100%"
                            class="connect fullwidth"
                            :elevation="0"
                            @click="back"
                            >Retour
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="#93B121"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import sharedForm from "@/components/shared/form";
import api from "@/api/users";
export default {
  components: {
    sharedForm,
  },

  data() {
    return {
      fields: [
        {
          type: "email",
          label: "Votre email",
          value: "",
          rules: "required|email",
          placeholder: "Votre email",
          name: "email",
        },
      ],
      btn: [
        {
          label: "Demander un nouveau mot de passe",
          method: "connect",
          type: "btn",
        },
      ],
      snackbar: false,
      timeout: 3000,
      errors: [],
    };
  },

  methods: {
    sendEmail: function () {
      let data = {};
      this.fields.forEach((field) => {
        data[field.name] = field.value;
      });
      api
        .forgetPassword(data)
        .then(() => {
          this.errors=[]
          this.snackbar = false;
          let message =
            "Un email vous a été envoyé à l'adresse mail suivante : " +
            data.email +
            " pour réinitialisé votre mot de passe";
          this.errors.push(message);
          this.snackbar = true;
          this.$router.push({ name: "login" });
        })
        .catch(() => {
           this.errors=[]
          this.snackbar = false;
          let message =
            "Un email vous a été envoyé à l'adresse mail suivante : " +
            data.email +
            " pour réinitialisé le mot de passe";
          this.errors.push(message);
          this.snackbar = true;
        });
      // alert("Email envoyé à l'adresse : " + this.fields[0].value);
    },
    back(){
      this.$router.push({name:'login'})
    }
  },
};
</script>

<style scoped>
.container#pageLogin {
  align-items: center;
  display: flex;
  height: 100vh;
}

.v-card.v-card.login {
  height: 78vh;
}

.form {
  height: 100%;
}

.form-title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #93b121;
  text-transform: uppercase;
}
.init-password {
  font-size: 0.8rem;
  font-weight: bold;
}
@media screen and (max-width: 1500px) {
   
    img{
        width: 40%;
    }
    /* .buttons .connect {
	
	font-size: 15px;
	cursor: pointer;
} */
}

@media screen and (max-width: 1400px) {
  /* .v-card {
    height: 70vh;
  } */
}

@media screen and (max-width: 1366px) {
  img {
    width: 40% !important;
  }
}
@media screen and (max-width: 1000px) {
  .v-card.login {
    height: 60vh;
  }
}
</style>